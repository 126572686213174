import React, { Component } from 'react';
import axios from 'axios';
import { Layout } from 'antd';

import Sider from './components/Sider';
import Map from './components/Map';

const { CancelToken } = axios;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 103.8556,
      lat: 1.2958,
      radius: 1000,
      pageSize: 100,
      scooters: [],
      errors: {},
      loading: false,
    };
    this.searchScooters = this.searchScooters.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() { this.searchScooters(); }

  async searchScooters() {
    try {
      const {
        lng,
        lat,
        radius,
        pageSize,
      } = this.state;

      if (this.cancelSearch && typeof this.cancelSearch === 'function') {
        await this.cancelSearch('Previous Request Cancelled due to New Request');
        await this.setState({ loading: false });
      }

      await this.setState({ loading: true });

      const scooters = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/scooters`,
        params: {
          queries: JSON.stringify({
            filters: { lng, lat, radius },
            pagination: { page: 1, pageSize },
          }),
        },
        cancelToken: new CancelToken((c) => { this.cancelSearch = c; }),
      })
        .then(res => res.data)
        .then(json => json.data);

      await this.setState({ scooters, loading: false });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          loading: false,
          errors: { message: err.message || 'There was an error fetching data from the server' },
        });
        throw err;
      }
    }
  }

  async handleChange({ field, value } = {}) {
    await this.setState({ [field]: value });

    if (field === 'lng' && (value < -180 || value > 180)) {
      await this.setState(prev => ({
        errors: {
          ...prev.errors,
          lng: 'Longitude must be between -180 and 180',
        },
      }));
    } else if (field === 'lat' && (value < -90 || value > 90)) {
      await this.setState(prev => ({
        errors: {
          ...prev.errors,
          lat: 'Latitude must be between -90 and 90',
        },
      }));
    } else if (field === 'radius' && (value < 0 || value > 20015086.796)) {
      await this.setState(prev => ({
        errors: {
          ...prev.errors,
          radius: 'Radius must be between 0 and 20015086.796',
        },
      }));
    } else if (field === 'pageSize' && (value < 1 || value > 200)) {
      await this.setState(prev => ({
        errors: {
          ...prev.errors,
          pageSize: 'Count must be between 1 and 200',
        },
      }));
    } else {
      await this.setState({ errors: {} });
      await this.searchScooters();
    }
  }

  render() {
    const {
      lng,
      lat,
      radius,
      scooters,
      pageSize,
      errors,
      loading,
    } = this.state;

    return (
      <Layout>
        <Sider
          lng={lng}
          lat={lat}
          radius={radius}
          pageSize={pageSize}
          errors={errors}
          loading={loading}
          handleChange={this.handleChange}
        />
        <Layout>
          <Map
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_MAP_KEY}`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '100vh' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            lat={lat}
            lng={lng}
            radius={radius}
            scooters={scooters}
          />
        </Layout>
      </Layout>
    );
  }
}

export default App;
