import React from 'react';
import PropTypes from 'prop-types';
import { Spin, Form } from 'antd';

import InputNumber from './atoms/InputNumber';
import ErrorMsg from './atoms/ErrorMsg';

function SearchForm(props) {
  const {
    lng,
    lat,
    radius,
    pageSize,
    errors,
    loading,
    handleChange,
  } = props;

  return (
    <Spin spinning={loading} tip="Loading...">
      <Form>
        <Form.Item
          label="Latitude"
          validateStatus={(errors.lat && 'error') || ''}
          help={errors.lat}
        >
          <InputNumber
            min={-90}
            max={90}
            value={lat}
            step={0.01}
            onChange={value => handleChange({ field: 'lat', value })}
            width="100%"
          />
        </Form.Item>

        <Form.Item
          label="Longitude"
          validateStatus={(errors.lng && 'error') || ''}
          help={errors.lng}
        >
          <InputNumber
            min={-180}
            max={180}
            value={lng}
            step={0.01}
            onChange={value => handleChange({ field: 'lng', value })}
            width="100%"
          />
        </Form.Item>

        <Form.Item
          label="Radius (meters)"
          validateStatus={(errors.radius && 'error') || ''}
          help={errors.radius}
        >
          <InputNumber
            min={0}
            max={20015086.796}
            value={radius}
            step={1000}
            onChange={value => handleChange({ field: 'radius', value })}
            width="100%"
          />
        </Form.Item>

        <Form.Item
          label="Count"
          validateStatus={(errors.pageSize && 'error') || ''}
          help={errors.pageSize}
        >
          <InputNumber
            min={1}
            max={200}
            value={pageSize}
            step={1}
            precision={0}
            onChange={value => handleChange({ field: 'pageSize', value })}
            width="100%"
          />
        </Form.Item>

        {errors.message !== undefined && <ErrorMsg>{errors.message}</ErrorMsg>}
      </Form>
    </Spin>
  );
}

SearchForm.propTypes = {
  lng: PropTypes.number,
  lat: PropTypes.number,
  radius: PropTypes.number,
  pageSize: PropTypes.number,
  errors: PropTypes.shape({}),
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
};

SearchForm.defaultProps = {
  lng: 103.8556,
  lat: 1.2958,
  radius: 1000,
  pageSize: 100,
  errors: {},
  loading: false,
  handleChange: null,
};

export default SearchForm;
