import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';

function InputNumberStyled(props) {
  const {
    min,
    max,
    value,
    step,
    onChange,
    width,
  } = props;

  return (
    <InputNumber
      min={min}
      max={max}
      value={value}
      step={step}
      onChange={onChange}
      style={{ width }}
    />
  );
}

InputNumberStyled.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
  width: PropTypes.string,
};

InputNumberStyled.defaultProps = {
  min: null,
  max: null,
  value: null,
  step: null,
  onChange: null,
  width: '100%',
};

export default InputNumberStyled;
