import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';

import SearchForm from './SearchForm';
import A from './atoms/A';
import Span from './atoms/Span';
import Main from './atoms/Main';
import Footer from './atoms/Footer';

function Sider(props) {
  const {
    lng,
    lat,
    radius,
    pageSize,
    errors,
    loading,
    handleChange,
  } = props;

  return (
    <Layout.Sider
      collapsible
      breakpoint="xs"
      collapsedWidth="0"
      width={280}
      style={{
        zIndex: 100,
        backgroundColor: '#fff',
        padding: 10,
      }}
    >
      <Main>
        <header>
          <h2>Beam Engineering Exercise</h2>
        </header>

        <section>
          <SearchForm
            lng={lng}
            lat={lat}
            radius={radius}
            pageSize={pageSize}
            errors={errors}
            loading={loading}
            handleChange={handleChange}
          />
        </section>

        <Footer>
          <Span color="grey">
            Designed by
            {' '}
            <A
              href="https://www.darrelltzj.com/"
              target="_blank"
              rel="noopener noreferrer"
              color="grey"
            >
              DarrellTZJ
            </A>
          </Span>
        </Footer>
      </Main>
    </Layout.Sider>
  );
}

Sider.propTypes = {
  lng: PropTypes.number,
  lat: PropTypes.number,
  radius: PropTypes.number,
  pageSize: PropTypes.number,
  errors: PropTypes.shape({}),
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
};

Sider.defaultProps = {
  lng: 103.8556,
  lat: 1.2958,
  radius: 1000,
  pageSize: 200,
  errors: {},
  loading: false,
  handleChange: null,
};

export default Sider;
