import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
} from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps//lib/components/addons/MarkerClusterer';

export default withScriptjs(withGoogleMap((props) => {
  const {
    lat,
    lng,
    radius,
    scooters,
  } = props;

  return (
    <GoogleMap defaultZoom={15} center={{ lat, lng }}>
      <Marker
        position={{ lat, lng }}
        defaultLabel={{ text: 'Search Point' }}
        defaultIcon={{ url: 'https://maps.google.com/mapfiles/ms/icons/blue-pushpin.png' }}
      />

      <MarkerClusterer
        onClick={props.onMarkerClustererClick}
        averageCenter
        enableRetinaIcons
        gridSize={20}
      >
        {scooters.reduce((acc, scooter) => {
          const { loc, code } = scooter;
          return loc[0] !== undefined && loc[1] !== undefined ? [
            ...acc,
            (
              <Marker
                key={code}
                position={{ lat: loc[1], lng: loc[0] }}
                defaultLabel={{ text: code }}
              />
            ),
          ] : acc;
        }, [])}

        <Circle radius={+radius} center={{ lat, lng }} />
      </MarkerClusterer>
    </GoogleMap>
  );
}));
